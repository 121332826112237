exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-bsky-ts": () => import("./../../../src/pages/bsky.ts" /* webpackChunkName: "component---src-pages-bsky-ts" */),
  "component---src-pages-desk-desksetup-tsx": () => import("./../../../src/pages/desk/desksetup.tsx" /* webpackChunkName: "component---src-pages-desk-desksetup-tsx" */),
  "component---src-pages-sessionize-frame-tsx": () => import("./../../../src/pages/sessionizeFrame.tsx" /* webpackChunkName: "component---src-pages-sessionize-frame-tsx" */),
  "component---src-pages-speaking-tsx": () => import("./../../../src/pages/speaking.tsx" /* webpackChunkName: "component---src-pages-speaking-tsx" */),
  "component---src-templates-author-tsx": () => import("./../../../src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

